import { OcThreebars2 } from "solid-icons/oc";
import Logo from "./Logo";
import { createSignal, Show } from "solid-js";
import MobileMenu from "./mobile-menu/MobileMenu";
import CartIndicator from "./cart/CartIndicator";
import { A, useLocation } from "@solidjs/router";
import { IoBagCheckOutline } from "solid-icons/io";
import { BiRegularUser } from "solid-icons/bi";
import { AiOutlineSearch } from "solid-icons/ai";

export default function NavBar() {
	const [mobileMenuOpen, setMobileMenuOpen] = createSignal<boolean>(false);

	function toggleMobileMenu(e: MouseEvent) {
		setMobileMenuOpen(!mobileMenuOpen());
	}

	const location = useLocation();

	function getCurrentPath() {
		const untrailingSlashed = location.pathname.replace(/^\/|\/$/g, "");

		return untrailingSlashed;
	}

	return (
		<>
			<MobileMenu
				isOpen={mobileMenuOpen()}
				onCloseButtonClick={toggleMobileMenu}
				onToggle={toggleMobileMenu}
			/>

			<section
				class={`fixed left-0 top-0 z-[99999] w-full border-b bg-white py-2 md:relative md:z-[1] ${
					getCurrentPath() === "" ? "md:border-none" : ""
				} md:bg-transparent md:py-4`}
			>
				<div class="container">
					<div class="flex w-full items-center justify-between font-semibold">
						<Show when={getCurrentPath() !== "components"} fallback="&nbsp;">
							<button
								type="button"
								class="mobile-menu-open-button flex items-center text-xl focus:bg-companycolor md:hidden"
								onClick={toggleMobileMenu}
							>
								<span class="mr-2 text-xl">
									<OcThreebars2 />
								</span>
							</button>
						</Show>

						<div class="navbar-start hidden w-5/12 items-center justify-end md:flex">
							<A class="px-5 hover:text-companycolor" href="/">
								HOME
							</A>
							<A class="px-5 hover:text-companycolor" href="/components/">
								KOMPONEN
							</A>
						</div>

						<div class="navbar-center flex items-center justify-center md:w-2/12">
							<Logo
								class={`h-10 md:h-14 lg:h-16 ${getCurrentPath() === "components" ? "hidden md:inline-flex" : ""}`}
							/>
						</div>

						<div class="navbar-end flex items-center justify-start md:w-5/12">
							<A
								class="hidden px-5 hover:text-companycolor md:inline-block"
								href="/rakit/"
							>
								RAKIT PC
							</A>
							<div class="inline-flex pl-5 text-xl">
								<A
									class="group mr-1.5 hidden rounded-full p-2 transition duration-300 hover:bg-secondarycolor md:inline-block"
									href="/search/"
								>
									<AiOutlineSearch class="group-hover:fill-white" />
								</A>

								<CartIndicator />

								<a
									class="group ml-1.5 hidden rounded-full p-2 transition duration-300 hover:bg-secondarycolor md:inline-block"
									href="/checkout/"
									title="Checkout"
								>
									<IoBagCheckOutline class="group-hover:text-white" />
								</a>

								<a
									class="group ml-1.5 hidden rounded-full p-2 transition duration-300 hover:bg-secondarycolor md:inline-block"
									href="/my-account/"
									title="Account"
								>
									<BiRegularUser class="group-hover:fill-white" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
