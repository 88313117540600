const siteUrl = "https://youngs.id";
const baseApiUrlV1 = `${siteUrl}/wp-json/youngs/v1`;
const baseApiUrl = `${siteUrl}/wp-json/youngs/v2`;

export const AppConfig = {
	siteUrl: siteUrl,
	baseApiUrlV1: baseApiUrlV1,
	baseApiUrl: baseApiUrl,
	productsPerPage: 18,
};

export const RakitanTypes = [
	"processor",
	"motherboard",
	"ram",
	"storage",
	"storage_extra",
	"vga",
	"psu",
	"casing",
	"cpu_cooler",
	"fan",
	"monitor",
	"mouse",
	"keyboard",
];

export const uncategorizedCategoryId = 15;

export const customPcCategoryId = 190;

export const RakitanTypesBasedOnSocket = ["processor", "motherboard", "ram"];
