import { Component, Show, lazy } from "solid-js";
import { Route, RouteSectionProps, Router } from "@solidjs/router";
import BottomNavbar from "./components/BottomNavbar";
import NavBar from "./components/NavBar";
import SimpleFooter from "./components/SimpleFooter";

const LandingPage = lazy(() => import("./pages/LandingPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
const ProductCategoryPage = lazy(() => import("./pages/ProductCategoryPage"));
const ProductEtalasePage = lazy(() => import("./pages/ProductEtalasePage"));
// const ProductDetailPage = lazy(
// 	() => import("./pages/product/ProductDetailPage"),
// );
const RakitPc = lazy(() => import("./pages/RakitPcPage"));
// const ShopPage = lazy(() => import("./pages/ShopPage"));
// const LoginPage = lazy(() => import("./pages/LoginPage"));
// const Register = lazy(() => import("./pages/RegisterPage"));
// const BlogPage = lazy(() => import("./pages/BlogPage"));
// const BlogDetailPage = lazy(() => import("./pages/BlogDetailPage"));
// const ContactUs = lazy(() => import("./pages/ContactUsPage"));
// const AboutUs = lazy(() => import("./pages/AboutUsPage"));
const ComponentsPage = lazy(() => import("./pages/ComponentsPage"));
const SearchPage = lazy(() => import("./pages/SearchPage"));
// const CartPage = lazy(() => import("./pages/buy/CartPage"));
// const Profile = lazy(() => import("./pages/account/Profile"));
// const PaymentAccount = lazy(() => import("./pages/account/PaymentAccout"));
// const Address = lazy(() => import("./pages/account/Address"));
// const UbahPassword = lazy(() => import("./pages/account/UbahPassword"));
// const Checkout = lazy(() => import("./pages/buy/CheckoutPage"));
// const Payment = lazy(() => import("./pages/buy/PaymentPage"));
const CategoriesPage = lazy(() => import("./pages/CategoriesPage"));
const EtalaseListPage = lazy(() => import("./pages/EtalaseListPage"));

const pagePathsWithNoWrapper = [
	"/login",
	"/register",
	"/account/profile",
	"/account/payment",
	"/account/addres",
	"/account/password",
	"/welcome",
];

function PageWrapper(props: RouteSectionProps) {
	const currentUrl = window.location.href;
	const baseUrl = window.location.origin;
	const currentPath = currentUrl.replace(baseUrl, "");
	const hasWrapper = !pagePathsWithNoWrapper.includes(currentPath);

	return (
		<>
			<Show when={hasWrapper} fallback={props.children}>
				<div class="mobile-navbar-gap relative block h-14 md:hidden">
					&nbsp;
				</div>
				<NavBar />
				{props.children}
				{/* <ServicesArea /> */}
				<SimpleFooter hasBg={true} />
				<div class="md:hidden">
					<BottomNavbar />
				</div>
			</Show>
		</>
	);
}

const App: Component = () => {
	return (
		<>
			<Router root={PageWrapper} explicitLinks={true}>
				{/* <Route path="/login" component={LoginPage} />
				<Route path="/register" component={Register} />
				<Route path="/account/profile" component={Profile} />
				<Route path="/account/payment" component={PaymentAccount} />
				<Route path="/account/addres" component={Address} />
				<Route path="/account/password" component={UbahPassword} /> */}
				<Route path="/welcome" component={LandingPage} />

				<Route path="/product-categories" component={CategoriesPage} />
				<Route path="/product-etalases" component={EtalaseListPage} />
				<Route path="/product-category/:slug" component={ProductCategoryPage} />
				<Route path="/product-etalase/:slug" component={ProductEtalasePage} />
				{/* <Route path="/product/:slug" component={ProductDetailPage} /> */}
				<Route path="/rakit" component={RakitPc} />
				<Route path="/components" component={ComponentsPage} />
				<Route path="/search" component={SearchPage} />
				{/* <Route path="/shop" component={ShopPage} />
				<Route path="/blog" component={BlogPage} />
				<Route path="/contact" component={ContactUs} />
				<Route path="/about" component={AboutUs} />
				<Route path="/cart" component={CartPage} />
				<Route path="/checkout" component={Checkout} />
				<Route path="/payment" component={Payment} />
				<Route path="/:slug" component={BlogDetailPage} /> */}

				<Route path="/" component={HomePage} />
			</Router>
		</>
	);
};

export default App;
