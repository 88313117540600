import { A } from "@solidjs/router";
import { Show } from "solid-js";

export interface MobileMenuItemProps {
	text: string;
	href: string;
	targetType?: "internal" | "external";
	onClick?: (e: MouseEvent) => void;
}

export default function MobileMenuItem(props: MobileMenuItemProps) {
	const targetType = props.targetType ?? "internal";

	return (
		<li class="text-md mb-4 font-medium">
			<Show
				when={targetType === "internal"}
				fallback={
					<a
						href={props.href}
						class="active:text-companycolor"
						onClick={props.onClick}
					>
						{props.text}
					</a>
				}
			>
				<A
					href={props.href}
					class="active:text-companycolor"
					onClick={props.onClick}
				>
					{props.text}
				</A>
			</Show>
		</li>
	);
}
